<template>
  <div class="section section-signup mt-0 pt-0">
    <div class="fixed-top">
      <alert v-if="hasErrorOnSubmit || hasServerError" type="danger" dismissible>{{ errorMessage }}</alert>
      <alert v-if="successfulSubmit" type="success" dismissible>
        <strong>Well done!</strong> Your registration email has been sent.
      </alert>
    </div>

    <div class="container pt-5">
      <div class="row">
        <div class="col-lg-8 text-center ml-auto mr-auto col-md-10 col-sm-10">
          <div class="container py-4 px-4">
            <h5 class="title-up">Child's Information</h5>
            <fg-input
              class="input-lg"
              :class="{ 'has-danger': $v.form.student.name.$invalid && isSubmitted }"
              placeholder="Child's Full Name"
              @keyup="handleKeyInput"
              v-model="form.student.name"
            ></fg-input>

            <fg-input class="input-lg">
              <el-date-picker
                :class="{ 'has-danger': $v.form.student.dob.$invalid && isSubmitted }"
                v-model="form.student.dob"
                type="date"
                @keyup="handleKeyInput"
                placeholder="Date of Birth"
                popper-class="date-picker-light"
              ></el-date-picker>
            </fg-input>

            <!-- <div class="col-md-4">
								<fg-input>
									<drop-down>
										<n-button slot="title" type="primary" class="dropdown-toggle m-0" data-toggle="dropdown" block round>Gender</n-button>
										<a class="dropdown-item" href="#">Male</a>
										<a class="dropdown-item" href="#">Female</a>
									</drop-down>
								</fg-input>
            </div>-->

            <div class="row">
              <div class="col-md-6 pr-lg-0 pr-md-0">
                <fg-input
                  :class="{
										'has-danger': $v.form.student.school.$invalid && isSubmitted
									}"
                  @keyup="handleKeyInput"
                  v-model="form.student.school"
                  class="input-lg"
                  placeholder="School"
                ></fg-input>
              </div>
              <div class="col-md-6">
                <fg-input
                  :class="{ 'has-danger': $v.form.student.class.$invalid && isSubmitted }"
                  v-model="form.student.class"
                  class="input-lg"
                  @keyup="handleKeyInput"
                  placeholder="Class"
                ></fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 pr-lg-0 pr-md-0">
                <fg-input
                  @keyup="handleKeyInput"
                  v-model="form.student.homeaddress"
                  class="input-lg"
                  placeholder="Home Address"
                ></fg-input>
              </div>
              <div class="col-md-6">
                <fg-input
                  class="input-lg"
                  @keyup="handleKeyInput"
                  v-model="form.student.street"
                  placeholder="Street"
                ></fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 pr-lg-0 pr-md-0">
                <fg-input
                  class="input-lg"
                  @keyup="handleKeyInput"
                  v-model="form.student.city"
                  placeholder="City"
                ></fg-input>
              </div>
              <div class="col-md-6">
                <fg-input
                  @keyup="handleKeyInput"
                  class="input-lg"
                  v-model="form.student.state"
                  placeholder="State"
                ></fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 pr-lg-0 pr-md-0">
                <fg-input
                  @keyup="handleKeyInput"
                  class="input-lg"
                  v-model="form.student.zip"
                  placeholder="Zip Code"
                ></fg-input>
              </div>
            </div>
          </div>
          <div class="container py-4 border-top px-4">
            <h5 class="card-title title-up">Parent/Guardian's Information</h5>
            <fg-input
              class="input-lg"
              :class="{
								'has-danger': $v.form.parent.name.$invalid && isSubmitted
							}"
              @keyup="handleKeyInput"
              v-model="form.parent.name"
              placeholder="Full Name"
            ></fg-input>

            <fg-input class="input-lg" v-model="form.parent.contact.home" placeholder="Home Phone"></fg-input>
            <fg-input class="input-lg" v-model="form.parent.contact.cell" placeholder="Cell Phone"></fg-input>
            <fg-input class="input-lg" v-model="form.parent.contact.work" placeholder="Work Phone"></fg-input>
            <fg-input
              class="input-lg"
              :class="{
								'has-danger': $v.form.parent.contact.email.$invalid && isSubmitted
							}"
              @keyup="handleKeyInput"
              v-model="form.parent.contact.email"
              placeholder="Email"
            ></fg-input>
          </div>

          <div class="container border-top py-4 px-4">
            <h5 class="card-title title-up">Emergency Contact Information</h5>
            <fg-input
              v-model="form.emergency.name"
              :class="{
								'has-danger': $v.form.emergency.name.$invalid && isSubmitted
							}"
              @keyup="handleKeyInput"
              class="input-lg"
              placeholder="Full Name"
            ></fg-input>
            <fg-input
              class="input-lg"
              :class="{
								'has-danger': $v.form.emergency.relationship.$invalid && isSubmitted
							}"
              @keyup="handleKeyInput"
              v-model="form.emergency.relationship"
              placeholder="Relationship"
            ></fg-input>

            <fg-input
              class="input-lg"
              v-model="form.emergency.contact.home"
              placeholder="Home Phone"
            ></fg-input>
            <fg-input
              class="input-lg"
              v-model="form.emergency.contact.cell"
              placeholder="Cell Phone"
            ></fg-input>
            <fg-input
              class="input-lg"
              v-model="form.emergency.contact.work"
              placeholder="Work Phone"
            ></fg-input>
            <fg-input
              class="input-lg"
              :class="{
								'has-danger': $v.form.emergency.contact.email.$invalid && isSubmitted
							}"
              @keyup="handleKeyInput"
              v-model="form.emergency.contact.email"
              placeholder="Email"
            ></fg-input>
          </div>

          <div class="container border-top py-4 px-4">
            <h5 class="card-title title-up">Other Information</h5>
            <fg-input class="input-lg">
              <textarea
                class="form-control"
                v-model="form.otherInformation.moreInfo"
                name="name"
                rows="3"
                cols="1"
                placeholder="Tell us a little about your child"
              ></textarea>
            </fg-input>

            <fg-input class="input-lg">
              <textarea
                class="form-control"
                v-model="form.otherInformation.interestInTechnologyChild"
                name="name"
                rows="3"
                cols="1"
                placeholder="Tell us about your child’s interest in technology"
              ></textarea>
            </fg-input>

            <fg-input class="input-lg">
              <textarea
                class="form-control"
                v-model="form.otherInformation.interestInTechnologyParent"
                name="name"
                rows="3"
                cols="1"
                placeholder="Tell us about your interest in technology"
              ></textarea>
            </fg-input>
            <fg-input
              class="input-lg"
              :class="{
								'has-danger': $v.form.spamProtection.$invalid && isSubmitted
							}"
              @keyup="handleKeyInput"
              v-model="form.spamProtection"
              placeholder="What's the sum of 9+8? (spam protection)"
            ></fg-input>
          </div>
          <div>
            <n-button @click.prevent="submitted($v)" type="primary" round size="lg">Register Now</n-button>
          </div>
          <div class="text-center">
            <router-link to="/contact-us" class="btn btn-round btn-warning btn-lg">Contact Us</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, FormGroupInput, Button } from "@/components";
import { DatePicker } from "element-ui";
import { Alert } from "@/components";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { DropDown } from "../../components";
const CONFIG = require("../../config.json");
// import emailjs from 'emailjs-com';

export default {
  components: {
    Card,
    Alert,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [DatePicker.name]: DatePicker,
    DropDown
  },
  data() {
    return {
      form: {
        student: {
          name: "",
          dob: "",
          school: "",
          class: "",
          homeaddress: null,
          street: "",
          state: "",
          city: "",
          zip: ""
        },
        parent: {
          name: "",
          contact: { home: "", cell: "", work: "", email: "" }
        },
        emergency: {
          name: "",
          relationship: "",
          contact: { home: "", cell: "", work: "", email: "" }
        },
        otherInformation: {
          moreInfo: "",
          interestInTechnologyChild: "",
          interestInTechnologyParent: ""
        },
        spamProtection: ""
      },
      hasServerError: false,
      hasErrorOnSubmit: false,
      successfulSubmit: false,
      errorMessage: "",
      isSubmitted: false
    };
  },

  validations: {
    form: {
      student: {
        name: {
          required
        },

        dob: {
          required
        },
        school: {
          required
        },
        class: {
          required
        },
        homeaddress: {},
        street: {},
        state: {},
        city: {},
        zip: {}
      },
      parent: {
        name: {
          required
        },
        contact: {
          home: {},
          cell: {},
          work: {},
          email: {
            required,
            email
          }
        }
      },
      emergency: {
        name: {
          required
        },
        relationship: {
          required
        },
        contact: {
          home: {},
          cell: {},
          work: {},
          email: {
            required,
            email
          }
        }
      },
      otherInformation: {
        moreInfo: {},
        interestInTechnologyChild: {},
        interestInTechnologyParent: {}
      },
      spamProtection: {
        required,
        spamProtection(value) {
          return parseInt(value) === 17 ? true : false;
        }
      }
    }
  },
  methods: {
    submitted($v) {
      this.hasErrorOnSubmit = $v.form.$invalid;
      this.isSubmitted = true;

      if ($v.form.$invalid) {
        this.errorMessage =
          "Your Registration Form contains errors. Please try again.";

        return;
      }

      this.$http
        .post(CONFIG.EMAIL_API_ENDPOINT, {
          from: this.form.parent.contact.email,
          to: CONFIG.CODIVERSE_EMAIL_ADDRESS,
          subject: `CodiverseKids Registration Form -  Student: ${this.form.student.name}`,
          html: `
				<p>Please see the following Registration Information:</p>
				<ul>
					<li>Student's Name: ${this.form.student.name}</li>
					<li>Student's DOB: ${this.form.student.dob}</li>
					<li>School: ${this.form.student.school}</li>
					<li>Class: ${this.form.student.class}</li>
					<li>Home Address: ${this.form.student.homeaddress}</li>
					<li>Street: ${this.form.student.street}</li>
					<li>State: ${this.form.student.state}</li>
					<li>City: ${this.form.student.city}</li>
					<li>Zip: ${this.form.student.zip}</li>
					<li>Parent: ${this.form.parent.name}</li>
					<li>Parent Home Number: ${this.form.parent.contact.home}</li>
					<li>Parent Cell: ${this.form.parent.contact.cell}</li>
					<li>Parent Work Number: ${this.form.parent.contact.work}</li>
					<li>Parent Email: ${this.form.parent.contact.email}</li>
					<li>Emergency Contact: ${this.form.emergency.name}</li>
					<li>Relationship: ${this.form.emergency.relationship}</li>
					<li>Emergency Contact Phone: ${this.form.emergency.contact.home}</li>
					<li>Emergency Contact Cell: ${this.form.emergency.contact.cell}</li>
					<li>Emergency Contack Work: ${this.form.emergency.contact.work}</li>
					<li>Emergency Contact Email: ${this.form.emergency.contact.email}</li>
					<li>More Information: ${this.form.otherInformation.moreInfo}</li>
					<li>Child's Interest In Technology: ${this.form.otherInformation.interestInTechnologyChild}</li>
					<li>Parent's Interest in Technology: ${this.form.otherInformation.interestInTechnologyParent}</li>
				</ul>
		`
        })
        .then(result => {
          this.successfulSubmit = true;
          this.$v.$reset();
          this.form = {
            student: {
              name: "",

              dob: "",
              school: "",
              class: "",
              homeaddress: "",
              street: "",
              state: "",
              city: "",
              zip: ""
            },
            parent: {
              name: "",
              contact: { home: "", cell: "", work: "", email: "" }
            },
            emergency: {
              name: "",
              relationship: "",
              contact: { home: "", cell: "", work: "", email: "" }
            },
            otherInformation: {
              moreInfo: "",
              interestInTechnologyChild: "",
              interestInTechnologyParent: ""
            },
            spamProtection: ""
          };
        })
        .catch(err => {
          this.hasServerError = true;
          this.errorMessage = "We're sorry but an error occured. Please email your information directly to info@codiversekids.com!";
        });
    },
    handleKeyInput() {
      this.isSubmitted = false;
      this.hasErrorOnSubmit = false;
    }
  }
};
</script>
<style></style>
