<template>
	<div>
		<div class="page-header page-header-small clear-filter" filter-color="orange">
			<parallax class="page-header-image" style="background-image: url('img/codiverse-background.png')"></parallax>
			<div class="content-center">
				<div class="brand mt-10">
					<h1 class="title text-center my-0 pt-1">Register</h1>
				</div>
			</div>
		</div>
		<signup-form></signup-form>
	</div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
import SignupForm from './components/SignupForm';
import { Card } from '@/components';
export default {
	name: 'landing',
	bodyClass: 'landing-page',
	components: {
		[Button.name]: Button,
		[FormGroupInput.name]: FormGroupInput,
		Card,
		SignupForm
	},
	data() {
		return {
			form: {
				firstName: '',
				email: '',
				message: ''
			}
		};
	}
};
</script>
<style></style>
