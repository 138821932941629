<template>
  <div class="section pb-0 \pt-lg-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 my-lg-auto">
          <div class="text-center">
            <h3 class="title pt-0">Enroll Your Child for 2022!</h3>
            <h5 class="mb-2 lead">
              Learning to write programs stretches your mind, and helps you think better, creates a way of thinking about things that I think is helpful in all
              domains.
            </h5>

            <h5 class="mb-2">
              Bill Gates
              <br />
            </h5>
            <cite title="Source Title">(Co-Founder of Microsoft)</cite>
            <div>
              <router-link to="/signup" class="btn btn-primary btn-lg btn-round">Register Now</router-link>
              <router-link
                to="/contact-us"
                class="btn btn-primary btn-lg btn-simple btn-round"
              >Contact Us</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <el-carousel height="500px">
            <el-carousel-item>
              <img class="d-block" src="img/boys-with-robot.jpg" alt="First slide" />
              <div class="carousel-caption d-none d-md-block">
                <!-- <h5 class="description">Kids having fun while coding</!-->
              </div>
            </el-carousel-item>

            <el-carousel-item>
              <img class="d-block" src="img/kids-with-computer.jpg" alt="Fifth slide" />
              <div class="carousel-caption d-none d-md-block">
                <!-- <h5 class="description">Learning How to Code</h5> -->
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <img class="d-block" src="img/girl-on-computer.jpg" alt="Second slide" />
              <div class="carousel-caption d-none d-md-block">
                <!-- <h5 class="description">Kid Coders</h5> -->
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <img class="d-block" src="img/happy-boy-computer.jpg" alt="Third slide" />
              <div class="carousel-caption d-none d-md-block">
                <!-- <h5 class="description">Learning How to Code</h5> -->
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <img class="d-block" src="img/happy-girl-tablet.jpg" alt="Fourth slide" />
              <div class="carousel-caption d-none d-md-block">
                <!-- <h5 class="description">Learning How to Code</h5> -->
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, CarouselItem } from "element-ui";
export default {
  components: { [Carousel.name]: Carousel, [CarouselItem.name]: CarouselItem }
};
</script>
