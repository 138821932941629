<template>
  <div>
    <div class="page-header page-header-small clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image: url('img/codiverse-background.png')"
      ></parallax>
      <div class="content-center">
        <div class="brand mt-10">
          <h1 class="title text-center my-0 pt-1">The Story of Innovators</h1>
        </div>
      </div>
    </div>

    <div class="container px-3 py-5">
      <div class="row justify-content-between align-items-center">
        <div class="col-lg-6">
          <h3 class="text-dark">The Story of Innovators</h3>
          <p
            class="lead text-dark"
          >Join us as your kids write stories with embedded problems, create designs to solve those problems and then bring them to life with visual programming and with robots!</p>
          <p
            class="lead text-dark"
          >They will enjoy solving puzzles and breaking problems down to bite-sized chunks as we use the basic constructs of every program. These are the underlying roots of all program development that goes beyond a given program language.</p>
          <p
            class="lead text-dark"
          >With foundations set we can launch them into more advance concepts… but at first baby steps, baby steps… we will have fun, learn and repeat.</p>

          <div class="btn-wrapper">
            <router-link to="/signup" class="btn btn-round btn-primary btn-lg">Register Now</router-link>
            <router-link
              to="/contact-us"
              class="btn btn-primary btn-lg btn-simple btn-round"
            >Contact Us</router-link>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="transform-perspective-right">
            <card class="mt-3">
              <template>
                <h3 class="text-left mb-3">In Program Speak</h3>
              </template>
              <template>
                <div class="description text-light">
                  <code>
                    <div>BEGIN Program Codiverse</div>
                    <div>&nbsp;&nbsp;CREATE variable child_innovator of type red_class_coding_star</div>
                    <div>&nbsp;&nbsp;CREATE variable junior_innovator of type orange_class_coding_star</div>
                    <div>&nbsp;&nbsp;CREATE variable fun and INITIALISE to TRUE</div>
                    <div>&nbsp;&nbsp;INITIALISE child_innovator with knowledge</div>
                    <div>&nbsp;&nbsp;INITIALISE junior_innovator with knowledge</div>
                    <div>&nbsp;&nbsp;DO</div>
                    <div>&nbsp; &nbsp;&nbsp;&nbsp;ADD knowledge to child_innovator</div>
                    <div>&nbsp; &nbsp;&nbsp;&nbsp;ADD knowledge to junior_innovator</div>
                    <div>&nbsp; &nbsp;&nbsp;&nbsp;school_year &#8592; IsSchoolYearEnded()</div>
                    <div>&nbsp;&nbsp;WHILE fun EQUALS TRUE AND school_year EQUAL “NotEnded”</div>
                    <div>&nbsp;&nbsp;IF fun EQUALS FALSE OR school_year EQUAL “Ended”</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;CALL function ShowParentsExellenceOfRedClassandOrangeClassCoders()</div>
                    <div>END Program Codiverse</div>
                  </code>
                </div>
              </template>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="section py-4" data-background-color="yellow">
      <div class="container pr-0">
        <div class="row justify-content-center py-3">
          <div class="text-center col-md-10 col-lg-6 col-sm-10 px-sm-2">
            <h3 class="title text-light my-0">The Story of Innovators</h3>
            <h5 class="description text-light">
              Join us as your kids write stories with embedded problems, create designs to solve those problems and then bring them to life with visual
              programming and with robots! They will enjoy solving puzzles and breaking problems down to bite-sized chunks as we use the basic constructs of every
              program. These are the underlying roots of all program development that goes beyond a given program language. With foundations set we can launch
              them into more advance concepts… but at first baby steps, baby steps… we will have fun, learn and repeat.
            </h5>

            <div class="text-center">
              <router-link to="/signup" class="btn btn-round btn-primary btn-lg">Register Now</router-link>
              <router-link
                to="/contact-us"
                class="btn btn-primary btn-lg btn-simple btn-round"
              >Contact Us</router-link>
            </div>
          </div>
          <div class="col-lg-6 col-md-10 section-story-overview p-0 my-lg-auto">
            <div class="image-container">
              <img src="img/test-bg.png" />
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import { Card } from "@/components";
export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,

    Card
  },
  data() {
    return {};
  }
};
</script>
<style></style>
