<template>
	<div>
		<div class="page-header page-header-small clear-filter" filter-color="orange">
			<parallax class="page-header-image" style="background-image: url('img/codiverse-background.png')"></parallax>
			<div class="content-center">
				<div class="brand mt-10">
					<h1 class="title text-center my-0 pt-1">How It Works</h1>
				</div>
			</div>
		</div>

		<div class="container p-3 ">
			<div class="row justify-content-center text-center">
				<div class="col-lg-10">
					<h2 class="text-dark mb-1">After-School Coding Club</h2>
					<div class="row">
						<div class="col-md-4 description text-dark">
							<h4>Programming and Problem Solving</h4>
						</div>
						<div class="col-md-4 description text-dark">
							<h4>Robotics</h4>
						</div>
						<div class="col-md-4 description text-dark">
							<h4>Fun Learning Activities</h4>
						</div>
					</div>
					<div class="lead text-dark">
						We focus on accessibility with
						<span class="text-primary"><strong>$250 per term</strong></span>
						(10% discount for additional siblings)
						<ul class="list-group mt-3 mb-3">
							<li class="list-group-item list-group-item-action">8 students to one instructor</li>
							<li class="list-group-item list-group-item-action">Students are provided with new laptops for programming and use in class</li>
							<li class="list-group-item list-group-item-action">Students are provided with robots for programming and use in class</li>
							<li class="list-group-item list-group-item-action">1 laptop per child for individual programming activities</li>
							<li class="list-group-item list-group-item-action">1 laptop to 2 children for peer programming activities – simulates real-world collaboration</li>
							<li class="list-group-item list-group-item-action">1 robot per child for individual robotics exercises</li>
							<li class="list-group-item list-group-item-action">1 robot per 2-3 children for team challenges</li>
						</ul>
					</div>

					<div class="btn-wrapper">
						<router-link to="/signup" class="btn btn-round btn-primary btn-lg">Register Now</router-link>
						<router-link to="/contact-us" class="btn btn-primary btn-lg btn-simple btn-round">Contact Us</router-link>
					</div>
				</div>
				<!-- <div class="col-lg-6">
					<div>
						<h3 class="text-dark">Levels</h3>
						<div class="lead text-dark">Just like the stars in our solar system, our coding stars will burn brighter and brighter as their skills deepen.</div>
						<card class="mt-3 p-3" color="orange">
							<template>
								<h3 class="text-center mb-3">Orange Class Coding Stars</h3>
							</template>
							<template>
								<div class="description text-center text-dark">9 to 11 years old Our Junior Beginner Class</div>
								<div class="row text-center text-light">
									<div class="col">Wednesdays</div>
									<div class="col">2:45 pm to 4:00pm</div>
								</div>
							</template>
						</card>

						<card class="mt-3" color="danger">
							<template>
								<h3 class="text-center mb-3">Red Class Coding Stars</h3>
							</template>
							<template>
								<div class="description text-dark">6 to 8 years old Our Youngest Beginner Class</div>
								<div class="row">
									<div class="col">Fridays</div>
									<div class="col">2:45 pm to 4:00pm</div>
								</div>
							</template>
						</card>

						<div class="lead text-dark">We will offer more advanced levels as students progress.</div>
					</div>
				</div> -->
			</div>
		</div>
		<div class="section py-0 mx-0">
			<div class="row">
				<!-- <div class="col-md-10 ml-auto mr-auto text-center">
					<h2 class="title text-primary">New After-School Coding Club at St. Gabriel’s School</h2>
					<div class="row ">
						<div class="col-md-4 description">
							<h5>Programming and Problem Solving</h5>
						</div>
						<div class="col-md-4 description">
							<h5>Robotics</h5>
						</div>
						<div class="col-md-4 description">
							<h5>Fun Learning Activities</h5>
						</div>
					</div>
					<h3 class="description mb-0">
						We focus on accessibility with
						<span class="text-primary">$250 per term</span>
					</h3>
					<p>(10% discount for additional siblings)</p>
					<div data-background-color="orange">
						<h2>Details</h2>
						<h5 class="description">
							8 students to one instructor Students are provided with new laptops for programming and use in class Students are provided with robots for
							programming and use in class 1 laptop per child for individual programming activities 1 laptop to 2 children for peer programming activities –
							simulates real-world collaboration 1 robot per child for individual robotics exercises 1 robot per 2-3 children for team challenges
						</h5>
					</div>
				</div> -->
			</div>

			<div class="section px-1" data-background-color="black">
				<div class="row">
					<div class="col-md-10 ml-auto mr-auto text-center">
						<h2 class="title text-warning">Levels</h2>
						<h4>Just like the stars in our solar system, our coding stars will burn brighter and brighter as their skills deepen.</h4>
						<div class="row justify-content-center text-center">
							<div class="col-lg-5 col-sm-10">
								<card class="mt-3" color="orange">
									<template>
										<h3 class="text-center mb-3">Orange Class Coding Stars</h3>
									</template>
									<template>
										<div class="row pt-2 text-center justify-content-center">
											<div class="description">9 to 11 years old - Our Junior Beginner Class</div>
											<div class="col-6 description">Fridays</div>
											<div class="col-6 description">2:45 pm to 4:00 pm</div>
										</div>
									</template>

									<div slot="footer" class="card-footer text-center mb-2">
										<router-link to="/signup" class="btn btn-round btn-neutral btn-lg">Register Now!</router-link>
									</div>
								</card>
							</div>
							<div class="col-lg-5 col-sm-10">
								<card class="mt-3" color="danger">
									<template>
										<h3 class="text-center mb-3">Red Class Coding Stars</h3>
									</template>
									<template>
										<div class="row">
											<div class="row pt-2 text-center justify-content-center">
												<div class="description">6 to 8 years old - Our Youngest Beginner Class</div>

												<div class="col-6 description">Fridays</div>
												<div class="col-6 description">2:45 pm to 4:00 pm</div>
											</div>
										</div>
									</template>
									<div slot="footer" class="card-footer text-center mb-2">
										<router-link to="/signup" class="btn btn-round btn-neutral btn-lg">Register Now!</router-link>
									</div>
								</card>
							</div>
						</div>
						<h5 class="description">
							We will offer more advanced levels as students progress.
						</h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
import { Card } from '@/components';
export default {
	name: 'landing',
	bodyClass: 'landing-page',
	components: {
		[Button.name]: Button,
		[FormGroupInput.name]: FormGroupInput,

		Card
	},
	data() {
		return {};
	}
};
</script>
<style></style>
