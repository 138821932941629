<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="row justify-content-md-center text-center">
        <div class="text-center col-md-12 col-lg-8">
          <a
            target="_blank"
            href="#"
            class="btn btn-neutral btn-icon btn-twitter btn-round btn-lg"
            rel="tooltip"
            title="Follow us"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            target="_blank"
            href="#"
            class="btn btn-neutral btn-icon btn-facebook btn-round btn-lg"
            rel="tooltip"
            title="Like us"
          >
            <i class="fab fa-facebook-square"></i>
          </a>
          <a
            target="_blank"
            href="#"
            class="btn btn-neutral btn-icon btn-linkedin btn-lg btn-round"
            rel="tooltip"
            title="Follow us"
          >
            <i class="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="#">Codiverse Kids</a>
          </li>
          <li>
            <a href="#/about-us">About Us</a>
          </li>
          <li>
            <a href="#/innovators">The Story of Innovators</a>
          </li>
          <li>
            <a href="#/how-it-works">How It Works</a>
          </li>
          <li>
            <a href="#/signup">Register</a>
          </li>
          <li>
            <a href="#/contact-us">Contact Us</a>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
