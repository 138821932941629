<template>
  <div>
    <div class="page-header page-header-small clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image: url('img/codiverse-background.png')"
      ></parallax>
      <div class="content-center">
        <div class="brand mt-10">
          <h1 class="title text-center my-0 pt-1">About Us</h1>
        </div>
      </div>
    </div>
    <div class="section py-0 mx-0">
      <div class="row">
        <div class="col-10 ml-auto mr-auto text-center">
          <h2 class="title text-primary">About Us</h2>

          <h4>
            At
            <strong>Codiverse Kids</strong> we recognize that coding is the literacy of the 21st century.
          </h4>
          <h5 class="description lead">
            Our children are relaxed around technology and their comfort is their strength. They don’t worry about breaking the program, and why should they?
            <strong>That’s how they learn!</strong>
            Children are creative and enjoy seeing their ideas take shape in tangible implementations. Whether it is a game, a mobile app, a program that solves a
            social problem, building a website or making that robot dance! We look around the world and see a world that is changing fast and we look into ourselves
            and know that our children, from the new readers to our tweens can lead the world and drive that change. We know that the earlier they start, the faster
            they learn and the better they become.
            <br />
            <br />
            <strong>Codiverse Kids</strong> is the brainchild of Computer Science educators, researchers and innovators, each with over 25 years of experience in
            Computer Science education and experience in the Computer Science industry with large companies in North America and at home. We have taught coding to children in primary school, teenagers and we have taught adults. Let us bring paradigm shift home.
          </h5>
        </div>
      </div>
      <!-- <div class="separator separator-primary"></div> -->

      <div class="section-story-overview pt-1 pb-4">
        <div class="row">
          <div class="col-md-6 pb-sm-2">
            <img src="img/girl-tablet-classroom.jpg" />
          </div>
          <div class="col-md-6 pb-sm-2">
            <img src="img/codiverse-boy-robot.jpg" />
          </div>
        </div>
      </div>
      <div class="section px-1 pt-1" data-background-color="yellow">
        <div class="row">
          <div class="col-10 ml-auto mr-auto text-center">
            <h2 class="title">Our Philosophy</h2>
            <h4>
              Coding for me. Coding for you. Coding for fun...
              <br />...Coding for life. Coding so nice. Coding is fun!
            </h4>
            <h5
              class="description lead"
            >Your child will have fun while learning with instructors who prioritize foundation and growth. Diversity in the culture of innovation starts at our roots. Let’s water our youngest plants so that they can grow with strength.</h5>
            <h4>We want to catch the sparkle in your child’s eye and change the world!</h4>
            <h5
              class="description lead"
            >We will bring paradigm shift as we partner with you to develop your child’s problem solving skills and unleash their power. We offer a quality experience with accessible learning and attentiveness to your child.</h5>
          </div>
        </div>
      </div>
      <quote></quote>
      <!-- <section class="pt-3" data-background-color="black">
        <div class="container">
          <card color="warning" class="border-0">
            <div class="p-2">
              <div class="row align-items-center">
                <div class="col-lg-8">
                  <h3 class="text-white">ENROLL YOUR CHILD FOR 2022.</h3>
                  <blockquote class="blockquote pt-0">
                    <p
                      class="mb-0"
                    >Learning to write programs stretches your mind, and helps you think better, creates a way of thinking about things that I think is helpful in all domains.</p>
                    <footer class="blockquote-footer">
                      Bill Gates
                      <cite title="Source Title">(Co-Founder of Microsoft)</cite>
                    </footer>
                  </blockquote>
                </div>
                <div class="col-lg-3 ml-lg-auto">
                  <n-button type="neutral" round size="lg">Register Now!</n-button>
                </div>
              </div>
            </div>
          </card>
        </div>
      </section> -->
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import SignupForm from "./components/SignupForm";
import { Card } from "@/components";
import Quote from "./components/Quote";
export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Card,
    Quote,
    SignupForm
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: ""
      }
    };
  }
};
</script>
<style></style>
