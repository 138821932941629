<template>
  <div>
    <div class="page-header page-header-small clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image: url('img/codiverse-background.png')"
      ></parallax>
      <div class="content-center">
        <div class="brand mt-10">
          <h1 class="title text-center my-0 pt-3">Contact Us</h1>
        </div>
      </div>
    </div>
    <div class="section section-contact-us text-center pt-0">
      <alert v-if="hasErrorOnSubmit || hasServerError" type="danger" v-html="errorMessage"></alert>
      <alert v-if="successfulSubmit" type="success" dismissible>
        <strong>Well done!</strong> Your email has been sent.
      </alert>
      <div class="container">
        <h2 class="title">Questions?</h2>
        <div class="description">
          <p py-0 mb-0>
            Send us a message and we'll get back to you!
            <br />Or email us at
            <strong>info@codiversekids.com</strong>
          </p>
        </div>

        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              class="input-lg"
              :class="{ 'has-success': !$v.form.name.$invalid, 'has-danger': $v.form.name.$invalid && $v.form.name.$dirty }"
              placeholder="Full Name..."
              v-model="$v.form.name.$model"
              addon-left-icon="now-ui-icons users_circle-08"
            ></fg-input>
            <fg-input
              class="input-lg"
              :class="{ 'has-success': !$v.form.email.$invalid, 'has-danger': $v.form.email.$invalid && $v.form.email.$dirty }"
              placeholder="Email..."
              v-model="$v.form.email.$model"
              addon-left-icon="now-ui-icons ui-1_email-85"
            ></fg-input>
            <div class="textarea-container">
              <textarea
                class="form-control"
                :class="{ 'has-success': !$v.form.message.$invalid, 'has-danger': $v.form.message.$invalid && $v.form.message.$dirty }"
                name="name"
                rows="4"
                cols="80"
                v-model="$v.form.message.$model"
                placeholder="Type a message..."
              ></textarea>
            </div>
            <div class>
              <n-button
                @click.prevent="submitted($v)"
                type="primary"
                round
                block
                size="lg"
              >Send Message</n-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import { Alert } from "@/components";
import SignupForm from "./components/SignupForm";
import { required, email } from "vuelidate/lib/validators";
const CONFIG = require("../config.json");

import { Card } from "@/components";
export default {
  name: "contact-us",
  bodyClass: "contact-us-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Card,
    Alert,
    SignupForm
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: ""
      },
      hasServerError: false,
      hasErrorOnSubmit: false,
      successfulSubmit: false,
      errorMessage: ""
    };
  },
  validations: {
    form: {
      name: { required },
      email: { required, email },
      message: { required }
    }
  },
  methods: {
    submitted($v) {
      this.hasErrorOnSubmit = $v.form.$invalid;

      if ($v.form.$invalid) {
        this.errorMessage =
          "Your Contact Form contains errors. Please try again.";
        return;
      }

      const baseURI = CONFIG.EMAIL_API_ENDPOINT;
      this.$http
        .post(baseURI, {
          from: CONFIG.CODIVERSE_EMAIL_ADDRESS,
          to: this.form.email,
          subject: `CodiverseKids Contact Us Form -  ${this.form.email}`,
          plain: `
            The following individual requires more information about CodiverseKids:
            Name: ${this.form.name}
            Email: ${this.form.email}
            Message: ${this.form.message}`,
          html: `<p>The following individual requires more information about CodiverseKids</p> <ul><li>Name: ${this.form.name}</li><li>Email: ${this.form.email}</li><li>Message: ${this.form.message}</li></ul>`,
          template: "Contact-Us"
        })
        .then(result => {
          this.successfulSubmit = true;
          this.form = { name: "", email: "", message: "" };
        })
        .catch(err => {
          this.hasServerError = true;
          this.errorMessage = "We're sorry, but an error occured. Please email your question directly to info@codiversekids.com!";
        });
    }
  }
};
</script>
<style></style>
