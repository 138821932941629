<template>
  <navbar class="curve" type="white" menu-classes="ml-auto" position="fixed">
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img class="codiverse-logo" src="img/codiversekids-logo.png" alt />
      </router-link>
      <!-- <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">Coding for Me • Coding for You • Coding for Fun</div>
      </el-popover>-->
    </template>
    <!-- <div class="navbar-translate">
      <a class="navbar-brand" href="#pablo">Primary color</a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#example-navbar-primary"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      ></button>
    </div>-->

    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link class="nav-link" to="/about-us">
          <!-- <i class="now-ui-icons objects_globe"></i> -->
          <p>About Us</p>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link class="nav-link" to="/innovators">
          <!-- <i class="now-ui-icons objects_globe"></i> -->
          <p>The Story of Innovators</p>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link class="nav-link" to="/how-it-works">
          <!-- <i class="now-ui-icons objects_globe"></i> -->
          <p>How It Works</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/signup">
          <!-- <i class="now-ui-icons objects_globe"></i> -->
          <p>Register Now</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/contact-us">
          <!-- <i class="now-ui-icons objects_globe"></i> -->
          <p>Contact Us</p>
        </router-link>
      </li>

      <!-- <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)" @click="scrollToElement('philosophySection')">
          <i class="now-ui-icons objects_globe"></i>
          <p>Philosophy</p>
        </a>
      </li>-->
      <!-- <li class="nav-item">
        <a class="nav-link" href="#pablo">
          <i class="now-ui-icons users_circle-08"></i>
          <p>Profile</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#pablo">
          <i class="now-ui-icons ui-1_settings-gear-63"></i>
          <p>Settings</p>
        </a>
      </li>
      <drop-down tag="li" title="Examples" icon="now-ui-icons design_image" class="nav-item">
        <nav-link to="/landing">
          <i class="now-ui-icons education_paper"></i> Landing
        </nav-link>
        <nav-link to="/login">
          <i class="now-ui-icons users_circle-08"></i> Login
        </nav-link>
        <nav-link to="/profile">
          <i class="now-ui-icons users_single-02"></i> Profile
        </nav-link>
      </drop-down>-->
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from "@/components";
import { Popover } from "element-ui";

export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover
  },
  methods: {
    scrollToElement(element) {
      let element_id = document.getElementById(element);
      if (element_id) {
        const yOffset = -30;
        const y =
          element_id.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
        // element_id.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }
};
</script>

<style scoped></style>
