/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { DatePicker } from 'element-ui';
import App from './App.vue';
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import axios from 'axios';

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(DatePicker);
Vue.use(Vuelidate);
Vue.use(NowUiKit);

new Vue({
	router,
	render: h => h(App)
}).$mount('#app');
