<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax class="page-header-image" style="background-image: url('img/main.jpg')"></parallax>
      <div class="content-center">
        <div class="brand mt-10">
          <!-- <img src="img/codiverse-logo.png" style="width: 400px;" /> -->
          <h1 class="title text-center my-1 pt-1">Welcome to Codiverse Kids!</h1>
          <h3 class="text-center">Coding for Me • Coding for You • Coding for Fun</h3>
          <div class="text-center mt-2">
            <router-link to="/signup" class="btn btn-round btn-primary btn-xl">Register Now</router-link>
          </div>

          <!-- <div class="text-center">
						<a href="#pablo" class="btn btn-primary btn-icon btn-round">
							<i class="fab fa-facebook-square"></i>
						</a>
						<a href="#pablo" class="btn btn-primary btn-icon btn-round">
							<i class="fab fa-twitter"></i>
						</a>
						<a href="#pablo" class="btn btn-primary btn-icon btn-round">
							<i class="fab fa-google-plus"></i>
						</a>
          </div>-->
        </div>
      </div>
    </div>
    <section
      class="pt-0 section section-signup mt-0"
      style="background-image: url('img/grass-background.jpg'); background-size: cover; background-position: top center; min-height: 300px;"
    >
      <div class="container pb--10">
        <div class="row justify-content-center row-grid">
          <div class="col-lg-4 col-md-6 mt-md-1">
            <card
              cardHeight="100"
              hover
              class="card-about"
              color="primary"
              textColor="primary"
              header-classes="text-center"
            >
              <!-- <img slot="image" class="card-img-top" src="img/codiverse-boy-robot.jpg" alt="Card image cap"> -->
              <template slot="header">
                <h3 class="card-title title-up mt-3">About Us</h3>
              </template>

              <p class="card-text text-center">
                At
                <strong>Codiverse Kids</strong> we recognize that coding is the literacy of the 21st century. Our children are relaxed around technology and their
                comfort is their strength. They don’t worry about breaking the program, and why should they?
                <strong>That’s how they learn!</strong>
              </p>

              <div slot="footer" class="card-footer text-center mb-2">
                <router-link to="/about-us" class="btn btn-round btn-neutral btn-lg">Read More</router-link>
              </div>
            </card>
          </div>
          <div class="col-lg-4 col-md-6 mt-md-1">
            <card
              hover
              cardHeight="100"
              class="card-about"
              color="warning"
              header-classes="text-center"
            >
              <!-- <img slot="image" class="card-img-top" src="img/girl-tablet-classroom.jpg" alt="Card image cap"> -->
              <template slot="header">
                <h3 class="card-title title-up mt-3">Our Philosophy</h3>
              </template>

              <p class="card-text text-center">
                Coding for me. Coding for you. Coding for fun. Coding for life. Coding so nice. Coding is fun! Your child will have fun while learning with
                instructors who prioritize foundation and growth.
                <br />
                <br />
                <br />
              </p>

              <div slot="footer" class="card-footer text-center mb-2">
                <router-link to="/about-us" class="btn btn-round btn-neutral btn-lg">Read More</router-link>
              </div>
            </card>
          </div>

          <div class="col-lg-4 col-md-6 mt-md-1">
            <card
              cardHeight="100"
              hover
              class="card-about"
              color="primary"
              header-classes="text-center"
            >
              <!-- <img slot="image" class="card-img-top" src="img/bg1.jpg" alt="Card image cap"> -->
              <template slot="header">
                <h3 class="card-title title-up mt-3">The Story of Innovators</h3>
              </template>

              <p class="card-text text-center">
                Join us as your kids write stories with embedded problems, create designs to solve those problems and then bring them to life with visual
                programming and with robots!
                <br />
                <br />
                <br />
              </p>

              <div slot="footer" class="card-footer text-center mb-2">
                <router-link to="/innovators" class="btn btn-round btn-neutral btn-lg">Read More</router-link>
              </div>
            </card>
          </div>
        </div>
      </div>
    </section>
    <quote></quote>

    <!-- 
		<div class="section pb-0 mx-0" id="aboutUsSection">
			<div class="row">
				<div class="col-md-10 ml-auto mr-auto text-center">
					<h2 class="title text-primary">About Us</h2>

					<h4>At <strong>Codiverse</strong> we recognize that coding is the literacy of the 21st century.</h4>
					<h5 class="description">
						Our children are relaxed around technology and their comfort is their strength. They don’t worry about breaking the program, and why should they?
						<strong>That’s how they learn!</strong>
						Children are creative and enjoy seeing their ideas take shape in tangible implementations. Whether it is a game, a mobile app, a program that solves a
						social problem, building a website or making that robot dance! We look around the world and see a world that is changing fast and we look into ourselves
						and know that our children, from the new readers to our tweens can lead the world and drive that change. We know that the earlier they start, the faster
						they learn and the better they become.
						<br /><br />
						<strong>Codiverse</strong> is the brainchild of Computer Science educators, researchers and innovators, each with over 25 years of experience in
						Computer Science education and experience in the Computer Science industry with large companies in North America and at home. We have taught coding to
						children in primary school and we have taught adults. Let us bring paradigm shift home.
					</h5>
				</div>
			</div>

			<div class="section-story-overview">
				<div class="row">
					<div class="col-md-6">
						<div class="image-container image-left" style="background-image: url('img/girl-tablet-classroom.jpg')"></div>
					</div>
					<div class="col-md-6">
						<div class="image-container image-right" style="background-image: url('img/codiverse-boy-robot.jpg')"></div>
					</div>
				</div>
			</div>
			<div class="section px-1" data-background-color="orange">
				<div class="row">
					<div class="col-md-10 ml-auto mr-auto text-center">
						<h2 class="title">Our Philosophy</h2>
						<h4>Coding for me. Coding for you. Coding for fun... <br />...Coding for life. Coding so nice. Coding is fun!</h4>
						<h5 class="description">
							Your child will have fun while learning with instructors who prioritize foundation and growth. Diversity in the culture of innovation starts at our
							roots. Let’s water our youngest plants so that they can grow with strength. We want to catch the sparkle in your child’s eye and change the world!
							We will bring paradigm shift as we partner with you to develop your child’s problem solving skills and unleash their power. We offer a quality
							experience with accessible learning and attentiveness to your child.
						</h5>
					</div>
				</div>
			</div>
    </div>-->

    <!-- <div class="section section-tabs">
			<div class="container">
				<div class="title">
					<h4>Navigation Tabs</h4>
				</div>
			</div>
    </div>-->

    <!-- <div class="section py-0" data-background-color="black">
			<div class="container pr-0">
				<div class="row justify-content-md-center">
					<div class="text-center col-md-5 col-lg-5">
						<h3 class="title text-primary my-0">The Story of Innovators</h3>
						<h5 class="description text-black">
							Join us as your kids write stories with embedded problems, create designs to solve those problems and then bring them to life with visual
							programming and with robots! They will enjoy solving puzzles and breaking problems down to bite-sized chunks as we use the basic constructs of every
							program. These are the underlying roots of all program development that goes beyond a given program language. With foundations set we can launch
							them into more advance concepts… but at first baby steps, baby steps… we will have fun, learn and repeat.
						</h5>

						<div class="text-center">
							<a href="https://www.creative-tim.com/product/vue-now-ui-kit" class="btn btn-primary btn-lg btn-round" role="button">
								Register Now!
							</a>
							<a href="https://www.invisionapp.com/now" target="_blank" class="btn btn-primary btn-lg btn-simple btn-round" role="button">
								Contact Us
							</a>
						</div>
					</div>
					<div class="col section-story-overview  m-0">
						<div class="p-0 m-0 image-container" style="background-image: url('img/test-bg.png'); min-height: 100%;"></div>
					</div>
				</div>
			</div>
    </div>-->

    <!-- <div class="section py-0" data-background-color="">
			<div class="container pr-0">
				<div class="row justify-content-md-center">
					<div class="col section-story-overview  m-0">
						<div class="p-0 m-0 image-container" style="background-image: url('img/test-bg.png'); min-height: 100%;"></div>
					</div>
					<div class="text-center col-md-5 col-lg-5">
						<h2 class="title text-primary my-0 text-left">In program speak</h2>
						<h6 class="description text-left">
							<code>
								<div>BEGIN Program Codiverse</div>
								<div>&nbsp;&nbsp;CREATE variable child_innovator of type red_class_coding_star</div>
								<div>&nbsp;&nbsp;CREATE variable junior_innovator of type orange_class_coding_star</div>
								<div>&nbsp;&nbsp;CREATE variable fun and INITIALISE to TRUE</div>
								<div>&nbsp;&nbsp;INITIALISE child_innovator with knowledge</div>
								<div>&nbsp;&nbsp;INITIALISE junior_innovator with knowledge</div>
								<div>&nbsp;&nbsp;DO</div>
								<div>&nbsp; &nbsp;&nbsp;&nbsp;ADD knowledge to child_innovator</div>
								<div>&nbsp; &nbsp;&nbsp;&nbsp;ADD knowledge to junior_innovator</div>
								<div>&nbsp; &nbsp;&nbsp;&nbsp;school_year &#8592; IsSchoolYearEnded()</div>
								<div>&nbsp;&nbsp;WHILE fun EQUALS TRUE AND school_year EQUAL “NotEnded”</div>
								<div>&nbsp;&nbsp;IF fun EQUALS FALSE OR school_year EQUAL “Ended”</div>
								<div>&nbsp;&nbsp;&nbsp;&nbsp;CALL function ShowParentsExellenceOfRedClassandOrangeClassCoders()</div>
								<div>END Program Codiverse</div>
							</code>
						</h6>

						<div class="text-center">
							<a href="https://www.creative-tim.com/product/vue-now-ui-kit" class="btn btn-primary btn-lg btn-round" role="button">
								Register Now!
							</a>
							<a href="https://www.invisionapp.com/now" target="_blank" class="btn btn-primary btn-lg btn-simple btn-round" role="button">
								Contact Us
							</a>
						</div>
					</div>
				</div>
			</div>
    </div>-->

    <!-- <signup-form></signup-form> -->

    <!-- <div class="section section-team text-center">
			<div class="container">
				<h2 class="title">Here is our team</h2>
				<div class="team">
					<div class="row">
						<div class="col-md-4">
							<div class="team-player">
								<img src="img/avatar.jpg" alt="Thumbnail Image" class="rounded-circle img-fluid img-raised" />
								<h4 class="title">Romina Hadid</h4>
								<p class="category text-primary">Model</p>
								<p class="description">
									You can write here details about one of your team members. You can give more details about what they do. Feel free to add some
									<a href="#">links</a> for people to be able to follow them outside the site.
								</p>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-twitter"></i></a>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-instagram"></i></a>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-facebook-square"></i></a>
							</div>
						</div>
						<div class="col-md-4">
							<div class="team-player">
								<img src="img/ryan.jpg" alt="Thumbnail Image" class="rounded-circle img-fluid img-raised" />
								<h4 class="title">Ryan Tompson</h4>
								<p class="category text-primary">Designer</p>
								<p class="description">
									You can write here details about one of your team members. You can give more details about what they do. Feel free to add some
									<a href="#">links</a> for people to be able to follow them outside the site.
								</p>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-twitter"></i></a>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-linkedin"></i></a>
							</div>
						</div>
						<div class="col-md-4">
							<div class="team-player">
								<img src="img/eva.jpg" alt="Thumbnail Image" class="rounded-circle img-fluid img-raised" />
								<h4 class="title">Eva Jenner</h4>
								<p class="category text-primary">Fashion</p>
								<p class="description">
									You can write here details about one of your team members. You can give more details about what they do. Feel free to add some
									<a href="#">links</a> for people to be able to follow them outside the site.
								</p>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-google-plus"></i></a>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-youtube"></i></a>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-twitter"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>-->
    <!-- <div class="section section-contact-us text-center">
			<div class="container">
				<h2 class="title">Want to work with us?</h2>
				<p class="description">Your project is very important to us.</p>
				<div class="row">
					<div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
						<fg-input class="input-lg" placeholder="First Name..." v-model="form.firstName" addon-left-icon="now-ui-icons users_circle-08"> </fg-input>
						<fg-input class="input-lg" placeholder="Email Here..." v-model="form.email" addon-left-icon="now-ui-icons ui-1_email-85"> </fg-input>
						<div class="textarea-container">
							<textarea class="form-control" name="name" rows="4" cols="80" v-model="form.message" placeholder="Type a message..."></textarea>
						</div>
						<div class="send-button">
							<n-button type="primary" round block size="lg">Send Message</n-button>
						</div>
					</div>
				</div>
			</div>
    </div>-->

    <!-- <codiverse-philosophy></codiverse-philosophy> -->
    <!-- <div class="section section-team text-center" data-background-color="black">
			<div class="container">
				<div class="team">
					<div class="row">
						<div class="col-md-10 ml-auto mr-auto text-center">
							<div class="team-player">
							
								<h2 class="title">The Story of Innovators</h2>
								<p class="description ">
									Join us as your kids write stories with embedded problems, create designs to solve those problems and then bring them to life with visual
									programming and with robots! They will enjoy solving puzzles and breaking problems down to bite-sized chunks as we use the basic constructs
									of every program. These are the underlying roots of all program development that goes beyond a given program language. With foundations set
									we can launch them into more advance concepts… but at first baby steps, baby steps… we will have fun, learn and repeat.
								</p>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-twitter"></i></a>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-instagram"></i></a>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-facebook-square"></i></a>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4 ml-auto mr-auto">
							<img src="img/kid-coder.jpg" />
						</div>
						<div class="col-md-8 ml-auto mr-auto text-center">
							<div class="team-player">
						
								<h2 class="title text-left">In program speak</h2>
								<p class="description text-left">
									<code>
										<div>BEGIN Program Codiverse</div>
										<div>&nbsp;&nbsp;CREATE variable child_innovator of type red_class_coding_star</div>
										<div>&nbsp;&nbsp;CREATE variable junior_innovator of type orange_class_coding_star</div>
										<div>&nbsp;&nbsp;CREATE variable fun and INITIALISE to TRUE</div>
										<div>&nbsp;&nbsp;INITIALISE child_innovator with knowledge</div>
										<div>&nbsp;&nbsp;INITIALISE junior_innovator with knowledge</div>
										<div>&nbsp;&nbsp;DO</div>
										<div>&nbsp; &nbsp;&nbsp;&nbsp;ADD knowledge to child_innovator</div>
										<div>&nbsp; &nbsp;&nbsp;&nbsp;ADD knowledge to junior_innovator</div>
										<div>&nbsp; &nbsp;&nbsp;&nbsp;school_year &#8592; IsSchoolYearEnded()</div>
										<div>&nbsp;&nbsp;WHILE fun EQUALS TRUE AND school_year EQUAL “NotEnded”</div>
										<div>&nbsp;&nbsp;IF fun EQUALS FALSE OR school_year EQUAL “Ended”</div>
										<div>&nbsp;&nbsp;&nbsp;&nbsp;CALL function ShowParentsExellenceOfRedClassandOrangeClassCoders()</div>
										<div>END Program Codiverse</div>
									</code>
								</p>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-twitter"></i></a>
								<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-linkedin"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>-->
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import Quote from "./components/Quote";
import { Card } from "@/components";

export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,

    Card,
    Quote
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: ""
      }
    };
  }
};
</script>
<style></style>
