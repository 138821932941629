import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import ContactUs from './pages/ContactUs.vue';
import Signup from './pages/Signup.vue';
import HowItWorks from './pages/HowItWorks.vue';
import Innovators from './pages/Innovators.vue';
import AboutUs from './pages/AboutUs.vue';
import CodiVerseNavbar from './layout/CodiVerseNavbar.vue';

import MainFooter from './layout/MainFooter.vue';

Vue.use(Router);

export default new Router({
	linkExactActiveClass: 'active',
	routes: [
		{
			path: '/',
			name: 'index',
			components: { default: Index, header: CodiVerseNavbar, footer: MainFooter },
			props: {
				header: { colorOnScroll: 100 },
				footer: { backgroundColor: 'black' }
			}
		},
		{
			path: '/about-us',
			name: 'about-us',
			components: { default: AboutUs, header: CodiVerseNavbar, footer: MainFooter },
			props: {
				header: { colorOnScroll: 100 },
				footer: { backgroundColor: 'black' }
			}
		},
		{
			path: '/signup',
			name: 'signup',
			components: { default: Signup, header: CodiVerseNavbar, footer: MainFooter },
			props: {
				header: { colorOnScroll: 100 },
				footer: { backgroundColor: 'black' }
			}
		},
		{
			path: '/contact-us',
			name: 'contact-us',
			components: { default: ContactUs, header: CodiVerseNavbar, footer: MainFooter },
			props: {
				header: { colorOnScroll: 100 },
				footer: { backgroundColor: 'black' }
			}
		},
		{
			path: '/innovators',
			name: 'innovators',
			components: { default: Innovators, header: CodiVerseNavbar, footer: MainFooter },
			props: {
				header: { colorOnScroll: 100 },
				footer: { backgroundColor: 'black' }
			}
		},
		{
			path: '/how-it-works',
			name: 'how-it-works',
			components: { default: HowItWorks, header: CodiVerseNavbar, footer: MainFooter },
			props: {
				header: { colorOnScroll: 100 },
				footer: { backgroundColor: 'black' }
			}
		}
	],
	scrollBehavior: to => {
		if (to.hash) {
			return { selector: to.hash };
		} else {
			return { x: 0, y: 0 };
		}
	}
});
